const googleAnalyticsConfig = {
  dataLayerName: 'ocConnectWidgetTracker',
  gtmId: 'GTM-PK78SWB'
};

function integrateGoogleAnalytics() {
  if (
    typeof window === 'undefined' ||
    window[googleAnalyticsConfig.dataLayerName]
  ) {
    return;
  }

  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    });
    const f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l !== 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(
    window,
    document,
    'script',
    googleAnalyticsConfig.dataLayerName,
    googleAnalyticsConfig.gtmId
  );
}

function trackGoogleAnalyticsEvent(event) {
  if (
    typeof window === 'undefined' ||
    !window[googleAnalyticsConfig.dataLayerName]
  ) {
    return;
  }

  window[googleAnalyticsConfig.dataLayerName].push(event);
}

function getDataLayer() {
  if (
    typeof window === 'undefined' ||
    !window[googleAnalyticsConfig.dataLayerName]
  ) {
    return;
  }

  return window[googleAnalyticsConfig.dataLayerName];
}

function integrateAnalytics() {
  integrateGoogleAnalytics();
}

function trackEvent(event) {
  trackGoogleAnalyticsEvent(event);
}

export const analytics = {
  integrateAnalytics,
  trackEvent,
  getDataLayer
};
